import React from 'react'

export type ReactMapGLModule = typeof import('react-map-gl')

function loadMapboxGL() {
  return Promise.all([
    import('react-map-gl'),
    // Dynamically imported css files don't have proper type annotations, but
    // that's okay.
    //@ts-expect-error
    import('mapbox-gl/dist/mapbox-gl.css'),
  ])
}

export function useMapGL() {
  const [reactMapGL, setReactMapGL] = React.useState<ReactMapGLModule>()
  const isLoaded = Boolean(reactMapGL)

  React.useEffect(() => {
    loadMapboxGL().then(([reactMapGL, mapboxGL]) => {
      setReactMapGL(reactMapGL)
    })
  }, [])

  return { reactMapGL, isLoaded }
}
