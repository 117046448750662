import clsx from 'clsx'
import { __DEV__ } from '../lib/constants'

interface Props {
  children: React.ReactNode
}

export const FallbackSlice = ({ children }: Props): JSX.Element | null => {
  if (!__DEV__) return null

  return (
    <div
      className={clsx(
        'py-12 text-white bg-[#B91C1C] text-18 text-center border',
        'border-black font-sans md:text-24'
      )}
    >
      Unable to find definition for: <strong>{children}</strong>
    </div>
  )
}
