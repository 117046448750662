import clsx from 'clsx'
import { graphql } from 'gatsby'
import { DownloadIcon } from '@heroicons/react/outline'

import { PageBodyResourcesFragment } from '../types.gql'
import { MapToPropsMetadata } from '../components/MapToComponents'
import { BoundedBoxTw } from '../components/BoundedBox'
import { HighlightedText } from '../components/HighlightedText'
import { HTMLContent } from '../components/HTMLContent/HTMLContent'
import { InfoBox } from '../components/InfoBox'
import { TextIconButton } from '../components/TextIconButton'
import { Link } from '../components/Link'

interface FileProps {
  name: string
  downloadUrl: string
}

const File = ({ name, downloadUrl }: FileProps) => {
  return (
    <li>
      <InfoBox
        as={Link}
        className={clsx(
          'h-full flex flex-col justify-between',
          'space-y-4 sm:space-y-8'
        )}
        href={downloadUrl}
        download
      >
        <h4 className="serif-18-1_35 sm:serif-24-1_5">{name}</h4>

        <TextIconButton as="div" label="Download File" icon={DownloadIcon}>
          Download
        </TextIconButton>
      </InfoBox>
    </li>
  )
}

const PageBodyResources = ({
  files = [],
  textHTML,
  heading,
  nextSharesBg,
}: ReturnType<typeof mapDataToProps>): JSX.Element => {
  return (
    <BoundedBoxTw
      className={clsx(
        'py-8 sm:py-10 md:py-16 lg:py-24 bg-gray-93',
        nextSharesBg && 'pb-0 sm:pb-0 md:pb-0 lg:pb-0'
      )}
      maxWidthClassName="max-w-[860px]"
    >
      <div className="grid gap-y-6 justify-items-center md:gap-y-8">
        {heading && <HighlightedText>{heading}</HighlightedText>}
        {textHTML && (
          <HTMLContent
            html={textHTML}
            className="text-center max-w-[75ch] mx-auto"
            variant="resources"
          />
        )}

        <ul
          className={clsx(
            'mt-4 border divide-y border-green-48 divide-green-48',
            'sm:grid sm:grid-flow-col sm:divide-y-0 sm:divide-x sm:auto-cols-fr'
          )}
        >
          {files.map((file, idx) => {
            if (!file.url || !file.name) return null

            return <File key={idx} downloadUrl={file.url} name={file.name} />
          })}
        </ul>
      </div>
    </BoundedBoxTw>
  )
}

export function mapDataToProps(
  data: PageBodyResourcesFragment,
  metadata: MapToPropsMetadata
) {
  return {
    files: data.items?.map((item) => ({
      name: item?.name,
      url: item?.file?.url,
    })),
    textHTML: data.primary?.text?.html,
    heading: data.primary?.small_heading?.text,
    nextSharesBg: metadata?.nextContext?.bg === 'bg-gray-93',
  }
}

export function mapDataToContext() {
  return {
    bg: 'bg-gray-93',
  }
}

export const fragment = graphql`
  fragment PageBodyResources on PrismicPageBodyResources {
    items {
      file {
        url
      }
      name
    }
    primary {
      small_heading {
        text
      }
      text {
        text
        html
      }
    }
  }
`

export default PageBodyResources
