import clsx from 'clsx'
import GatsbyImage, { FluidObject } from 'gatsby-image'
import { BoundedBoxTw } from '../components/BoundedBox'
import { Link } from '../components/Link'
import { MapToPropsMetadata } from '../components/MapToComponents'
import { useAllDoctors } from '../hooks/useAllDoctors'

interface DoctorProps {
  name?: string
  imageFluid?: FluidObject
  url?: string
}

const Doctor = ({ name, imageFluid, url }: DoctorProps) => {
  return (
    <Link
      className="grid items-center grid-cols-2 gap-4 sm:grid-cols-1 sm:items-stretch"
      href={url}
    >
      {imageFluid && (
        <div className="aspect-w-4 aspect-h-3">
          <div>
            <GatsbyImage
              fluid={imageFluid}
              alt={name}
              imgStyle={{ objectFit: 'cover' }}
              className="w-full h-full"
            />
          </div>
        </div>
      )}
      {name && <p className="serif-18-1_5">Dr. {name}</p>}
    </Link>
  )
}

const PageBodyDoctors = ({
  nextSharesBg,
}: ReturnType<typeof mapDataToProps>) => {
  const doctors = useAllDoctors()

  return (
    <BoundedBoxTw
      as="section"
      className={clsx(
        'py-16 sm:py-20 lg:py-24',
        nextSharesBg && 'pb-0 sm:pb-0 lg:pb-0'
      )}
      maxWidthClassName="max-w-md"
      innerClassName={clsx(
        'grid gap-y-4 sm:grid-cols-2 sm:gap-x-4 sm:gap-y-12 md:grid-cols-3'
      )}
    >
      {doctors.map((d, idx) => (
        <Doctor
          key={idx}
          name={d.data?.name?.text}
          imageFluid={d.data?.image?.fluid as FluidObject}
          url={d.url}
        />
      ))}
    </BoundedBoxTw>
  )
}

export function mapDataToProps(_data: unknown, metadata: MapToPropsMetadata) {
  return {
    nextSharesBg: metadata?.nextContext?.bg === 'bg-white',
  }
}

export function mapDataToContext() {
  return {
    bg: 'bg-white',
  }
}

export default PageBodyDoctors
