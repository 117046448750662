import { graphql } from 'gatsby'
import * as PageBodyHero from './PageBodyHero'
import * as PageBodySmallHero from './PageBodySmallHero'
import * as PageBodyCallToAction from './PageBodyCallToAction'
import * as PageBodyFeaturedNewsPost from './PageBodyFeaturedNewsPost'
import * as PageBodyDoctors from './PageBodyDoctors'
import * as PageBodyBreadcrumbs from './PageBodyBreadcrumbs'
import * as PageBodyCarousel from './PageBodyCarousel'
import * as PageBodyTextWithImage from './PageBodyTextWithImage'
import * as PageBodyTabs from './PageBodyTabs'
import * as PageBodyCtaWithImage from './PageBodyCtaWithImage'
import * as PageBodyFaq from './PageBodyFaq'
import * as PageBodyResources from './PageBodyResources'
import * as PageBodyLogos from './PageBodyLogos'
import * as PageBodyMap from './PageBodyMap'

import { SliceModule } from '../components/MapToComponents'

// 1. Spread your gql fragment. Ensure to include it at the bottom.
export const pageBodyFragment = graphql`
  fragment SlicesPageBody on PrismicPageBodySlicesType {
    ...PageBodyHero
    ...PageBodySmallHero
    ...PageBodyCallToAction
    ...PageBodyFeaturedNewsPost
    ...PageBodyCarousel
    ...PageBodyTextWithImage
    ...PageBodyTabs
    ...PageBodyCtaWithImage
    ...PageBodyFaq
    ...PageBodyResources
    ...PageBodyResources
    ...PageBodyLogos
    ...PageBodyMap

    # The following slices do not have fragments:
    # ...PageBodyBreadcrumbs
    # ...PageBodyDoctors
  }
`

// 2. Add your slice to the map.
export const sliceMap: Record<string, SliceModule> = {
  PageBodyHero,
  PageBodySmallHero,
  PageBodyCallToAction,
  PageBodyFeaturedNewsPost,
  PageBodyDoctors,
  PageBodyBreadcrumbs,
  PageBodyCarousel,
  PageBodyTextWithImage,
  PageBodyTabs,
  PageBodyCtaWithImage,
  PageBodyFaq,
  PageBodyResources,
  PageBodyLogos,
  PageBodyMap,
}
