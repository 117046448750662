import clsx from 'clsx'
import type { PolymorphicPropsWithoutRef } from 'react-polymorphic-types'

const defaultElement = 'div'

interface OwnProps {}

export type InfoBoxProps<T extends React.ElementType = typeof defaultElement> =
  PolymorphicPropsWithoutRef<OwnProps, T>

export const InfoBox = <T extends React.ElementType = typeof defaultElement>({
  as,
  className,
  children,
  ...props
}: InfoBoxProps<T>) => {
  const Element: React.ElementType = as || defaultElement

  return (
    <Element
      className={clsx(
        className,
        'block px-5 py-6 bg-white sm:px-6 md:px-8 md:py-10 lg:px-10 transition',
        'hover:bg-green-84 focus:bg-green-84 focus:ring focus:outline-none'
      )}
      {...props}
    >
      {children}
    </Element>
  )
}
