import clsx from 'clsx'
import type { PolymorphicPropsWithoutRef } from 'react-polymorphic-types'

import { VisuallyHidden } from './VisuallyHidden'

const IconButtonDefaultElement = 'button'

interface OwnProps {
  icon: (props: React.ComponentProps<'svg'>) => JSX.Element
  label: string
}

export type IconButtonProps<
  T extends React.ElementType = typeof IconButtonDefaultElement
> = PolymorphicPropsWithoutRef<OwnProps, T>

export const IconButton = <
  T extends React.ElementType = typeof IconButtonDefaultElement
>({
  as,
  icon: Icon,
  className,
  label,
  ...props
}: IconButtonProps<T>) => {
  const Element: React.ElementType = as || IconButtonDefaultElement

  return (
    <Element
      className={clsx(
        className,
        'flex items-center justify-center p-1 border border-gray-18 text-gray-18',
        'rounded-full transition'
      )}
      {...props}
    >
      <VisuallyHidden>{label}</VisuallyHidden>
      <Icon className="w-5 h-5" />
    </Element>
  )
}
