import clsx from 'clsx'
import { graphql } from 'gatsby'
import { Disclosure } from '@headlessui/react'
import { PlusIcon, MinusIcon } from '@heroicons/react/outline'

import { PageBodyFaqFragment } from '../types.gql'
import { MapToPropsMetadata } from '../components/MapToComponents'
import { BoundedBoxTw } from '../components/BoundedBox'
import { Button } from '../components/Button'
import { Link } from '../components/Link'
import { IconButton } from '../components/IconButton'
import { HTMLContent } from '../components/HTMLContent'

interface FaqItemProps {
  buttonText?: string
  buttonUrl?: string
  answerHTML: string
  question: string
}

const FaqItem = ({
  buttonText,
  buttonUrl,
  answerHTML,
  question,
}: FaqItemProps) => {
  return (
    <Disclosure
      as="li"
      className={clsx(
        'bg-white transition',
        'hover:bg-green-84 focus-within:bg-green-84'
      )}
    >
      {({ open }) => (
        <>
          <Disclosure.Button
            className={clsx(
              'relative w-full text-left select-none focus:outline-none group',
              'py-8 px-5 sm:px-6 md:px-8 md:py-10'
            )}
          >
            <h4 className="mr-12 serif-18-1_35 sm:serif-24-1_5">{question}</h4>

            <div
              className={clsx(
                'absolute',
                'top-8 md:top-8',
                'right-5 sm:right-6 md:right-8'
              )}
            >
              {open ? (
                <IconButton as="div" label="Close FAQ" icon={MinusIcon} />
              ) : (
                <IconButton as="div" label="Open FAQ" icon={PlusIcon} />
              )}
            </div>
          </Disclosure.Button>

          <Disclosure.Panel
            className={clsx(
              'relative flex flex-col items-start space-y-5',
              'px-5 sm:px-6 md:px-8 pb-8 md:pb-10',
              '-mt-2 md:-mt-4'
            )}
          >
            <HTMLContent
              html={answerHTML}
              variant="faqAnswer"
              className="w-10/12"
            />

            {buttonUrl && buttonText && (
              <Button as={Link} href={buttonUrl} className="block">
                {buttonText}
              </Button>
            )}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}

const PageBodyFaq = ({
  nextSharesBg,
  faqs = [],
}: ReturnType<typeof mapDataToProps>): JSX.Element => {
  return (
    <BoundedBoxTw
      className={clsx(
        'py-8 sm:py-10 md:py-16 lg:py-24 bg-gray-93',
        nextSharesBg && 'pb-0 sm:pb-0 md:pb-0 lg:pb-0'
      )}
      maxWidthClassName="max-w-[860px]"
    >
      <ul className="border divide-y border-green-48 divide-green-48">
        {faqs.map((faq, idx) => {
          if (!faq.question || !faq.answerHTML) return null

          return (
            <FaqItem
              key={idx}
              buttonText={faq.buttonText}
              buttonUrl={faq.buttonUrl}
              answerHTML={faq.answerHTML}
              question={faq.question}
            />
          )
        })}
      </ul>
    </BoundedBoxTw>
  )
}

export function mapDataToProps(
  data: PageBodyFaqFragment,
  metadata: MapToPropsMetadata
) {
  return {
    faqs: data.items?.map((item) => ({
      buttonText: item?.button_text,
      answerHTML: item?.answer?.html,
      buttonUrl: item?.button_url?.url,
      question: item?.question?.text,
    })),
    nextSharesBg: metadata?.nextContext?.bg === 'bg-gray-93',
  }
}

export function mapDataToContext() {
  return {
    bg: 'bg-gray-93',
  }
}

export const fragment = graphql`
  fragment PageBodyFaq on PrismicPageBodyFaq {
    items {
      button_text
      answer {
        html
      }
      button_url {
        url
      }
      question {
        text
      }
    }
  }
`

export default PageBodyFaq
