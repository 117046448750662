import { graphql, PageProps } from 'gatsby'
import { MapToComponents, Slice } from '../components/MapToComponents'
import { SEO } from '../components/SEO'
import { sliceMap } from '../slices/PageBody'
import { PageTemplateQuery } from '../types.gql'

const PageTemplate = ({ data: queryData }: PageProps<PageTemplateQuery>) => {
  const data = queryData!.prismicPage!.data!

  return (
    <>
      <SEO pageTitle={data?.title?.text ?? 'Page'} />
      <MapToComponents
        sliceMap={sliceMap}
        slices={data.body as Slice[]}
        rootData={queryData}
      />
    </>
  )
}

export default PageTemplate

export const query = graphql`
  query PageTemplate($uid: String!) {
    prismicPage(uid: { eq: $uid }) {
      _previewable
      uid
      ...PrismicPageParentRecursive
      data {
        title {
          text
        }
        meta_title
        meta_description
        body {
          __typename
          ... on Node {
            id
          }
          ...SlicesPageBody
        }
      }
    }
  }
`
