import { graphql, useStaticQuery } from 'gatsby'
import { AllDoctorsQuery } from '../types.gql'

export function useAllDoctors() {
  const data = useStaticQuery<AllDoctorsQuery>(graphql`
    query AllDoctors {
      allPrismicDoctor {
        nodes {
          url
          data {
            image {
              fluid(maxWidth: 600) {
                ...GatsbyPrismicImageFluid
              }
            }
            name {
              text
            }
          }
        }
      }
    }
  `)

  return data.allPrismicDoctor.nodes
}
