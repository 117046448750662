import clsx from 'clsx'
import { graphql } from 'gatsby'
import GatsbyImage from 'gatsby-image'

import { BoundedBoxTw } from '../components/BoundedBox'
import { Button } from '../components/Button'
import { Link } from '../components/Link'
import { PageBodyCtaWithImageFragment } from '../types.gql'

const PageBodyCtaWithImage = ({
  heading,
  imageFluid,
  buttonText,
  buttonUrl,
}: ReturnType<typeof mapDataToProps>): JSX.Element => {
  return (
    <BoundedBoxTw
      className={clsx('py-16 lg:py-24 bg-gray-93 relative')}
      maxWidthClassName="max-w-[860px]"
    >
      {imageFluid && (
        <div className="absolute inset-0 filter brightness-75">
          <GatsbyImage fluid={imageFluid} className="h-full" />
        </div>
      )}

      <div className="relative grid justify-items-center gap-y-8">
        <div>
          {heading && (
            <h3
              className={clsx(
                'text-white serif-24-1_5 sm:serif-30-1_5 md:serif-36-1_5',
                'font-bold'
              )}
            >
              {heading}
            </h3>
          )}
        </div>
        {buttonUrl && buttonText && (
          <Button
            as={Link}
            href={buttonUrl}
            className="block"
            variant="whiteOutline"
          >
            {buttonText}
          </Button>
        )}
      </div>
    </BoundedBoxTw>
  )
}

export function mapDataToProps(data: PageBodyCtaWithImageFragment) {
  return {
    heading: data.primary?.heading?.text,
    imageFluid: data.primary?.image?.fluid,
    buttonText: data.primary?.button_text,
    buttonUrl: data.primary?.button_url?.url,
  }
}

export function mapDataToContext() {
  return {
    bg: Symbol('unique'),
  }
}

export const fragment = graphql`
  fragment PageBodyCtaWithImage on PrismicPageBodyCtaWithImage {
    primary {
      button_text
      button_url {
        url
      }
      heading {
        text
      }
      image {
        fluid(maxWidth: 1000) {
          ...GatsbyPrismicImageFluid
        }
      }
    }
  }
`

export default PageBodyCtaWithImage
