import { getSliceTypeName } from '../lib/getSliceTypeName'
import { FallbackSlice } from './FallbackSlice'

export interface Slice {
  __typename: string
  id: string
}

interface MapToComponentsProps {
  sliceMap: Record<string, SliceModule>
  slices?: Slice[]
  rootData?: unknown
}

export interface MapToContextMetadata {
  slices: Slice[]
  idx: number
}

export interface MapToPropsMetadata<TRootData extends any = any>
  extends MapToContextMetadata {
  prevContext?: any
  context?: any
  nextContext?: any
  contexts?: any[]
  rootData?: TRootData
}

export type MapDataToContext<T extends any = any, R = any> = (
  data: T,
  metadata: MapToContextMetadata
) => R
export type MapDataToProps<T extends any = any, R = any> = (
  data: T,
  metadata: MapToPropsMetadata
) => R

export type SliceModule = {
  default: (props: any) => JSX.Element
  mapDataToContext?: MapDataToContext
  mapDataToProps?: MapDataToProps
}

export const MapToComponents = ({
  sliceMap,
  slices,
  rootData,
}: MapToComponentsProps) => {
  if (!slices) return null

  const contexts = slices.map((slice, idx) => {
    const module = sliceMap[getSliceTypeName(slice)]
    if (!module) return

    const { mapDataToContext } = module
    if (!mapDataToContext) return

    return mapDataToContext(slice, { idx, slices })
  })

  return (
    <>
      {slices.map((slice, idx) => {
        const module = sliceMap[getSliceTypeName(slice)]

        if (!module) {
          return (
            <FallbackSlice key={slice.id}>{slice.__typename}</FallbackSlice>
          )
        }

        const { default: SliceComponent, mapDataToProps } = module
        const props = mapDataToProps
          ? mapDataToProps(slice, {
              slices,
              idx,
              context: contexts[idx],
              prevContext: contexts[idx - 1],
              nextContext: contexts[idx + 1],
              contexts,
              rootData,
            })
          : undefined

        return <SliceComponent key={slice.id} {...props} />
      })}
    </>
  )
}
