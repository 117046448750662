import { graphql } from 'gatsby'
import clsx from 'clsx'

import { Button } from '../components/Button'
import { BoundedBoxTw } from '../components/BoundedBox'
import { Link } from '../components/Link'
import { HighlightedText } from '../components/HighlightedText'
import { PageBodyFeaturedNewsPostFragment } from '../types.gql'
import GatsbyImage, { FluidObject } from 'gatsby-image'
import { MapToPropsMetadata } from '../components/MapToComponents'

const PageBodyFeaturedNewsPost = ({
  buttonText,
  smallHeading,
  newsPostTitle,
  newsPostImageFluid,
  newsPostImageAlt,
  newsPostURL,
  nextSharesBg,
}: ReturnType<typeof mapDataToProps>) => {
  return (
    <BoundedBoxTw
      as="section"
      className={clsx(
        'py-8 sm:py-10 md:py-16 lg:py-24',
        nextSharesBg && 'pb-0 sm:pb-0 md:pb-0 lg:pb-0'
      )}
      maxWidthClassName="max-w-md"
      innerClassName="grid gap-8 sm:grid-cols-2 sm:grid-flow-col-dense items-center"
    >
      {newsPostImageFluid && (
        <div className="aspect-w-4 aspect-h-3 md:aspect-w-16 md:aspect-h-10 sm:col-start-2">
          <div>
            <GatsbyImage
              fluid={newsPostImageFluid}
              alt={newsPostImageAlt}
              imgStyle={{ objectFit: 'cover' }}
              className="h-full"
            />
          </div>
        </div>
      )}

      <div className="grid gap-6 justify-items-start sm:justify-self-center md:gap-8">
        {smallHeading && <HighlightedText>{smallHeading}</HighlightedText>}
        {newsPostTitle && (
          <h5 className="max-w-[30ch] serif-18-1_5 md:serif-24-1_5">
            {newsPostTitle}
          </h5>
        )}
        <Button
          as={Link}
          href={newsPostURL}
          variant="primary"
          className="block"
        >
          {buttonText}
        </Button>
      </div>
    </BoundedBoxTw>
  )
}

export function mapDataToProps(
  data: PageBodyFeaturedNewsPostFragment,
  metadata: MapToPropsMetadata
) {
  const newsPost = data.primary?.news_post

  return {
    buttonText: data?.primary?.button_text,
    smallHeading: data?.primary?.small_heading?.text,
    newsPostTitle: newsPost?.document?.data?.title?.text,
    newsPostImageFluid: newsPost?.document?.data?.featured_image
      ?.fluid as FluidObject,
    newsPostImageAlt: newsPost?.document?.data?.featured_image?.alt,
    newsPostURL: newsPost?.url,
    nextSharesBg: metadata?.nextContext?.bg === 'bg-white',
  }
}

export function mapDataToContext() {
  return {
    bg: 'bg-white',
  }
}

export const fragment = graphql`
  fragment PageBodyFeaturedNewsPost on PrismicPageBodyFeaturedNewsPost {
    primary {
      button_text
      news_post {
        url
        document {
          ... on PrismicNewsPost {
            id
            data {
              title {
                text
              }
              featured_image {
                fluid(maxWidth: 600) {
                  ...GatsbyPrismicImageFluid
                }
                alt
              }
            }
          }
        }
      }
      small_heading {
        text
      }
    }
  }
`

export default PageBodyFeaturedNewsPost
