import * as React from 'react'
import clsx from 'clsx'
import { graphql } from 'gatsby'
import { useKeenSlider } from 'keen-slider/react'
import GatsbyImage, { FluidObject } from 'gatsby-image'
import { ArrowRightIcon, ArrowLeftIcon } from '@heroicons/react/outline'

import { PageBodyCarouselFragment } from '../types.gql'
import { VisuallyHidden } from '../components/VisuallyHidden'
import { MapToPropsMetadata } from '../components/MapToComponents'

const CircleButton = ({
  className,
  children,
  ...props
}: React.ComponentProps<'button'>) => {
  return (
    <button
      className={clsx(
        'flex items-center justify-center border border-black border-solid',
        'rounded-full p-1.5 focus:outline-none focus:ring',
        'bg-white hover:bg-gray-93 transition',
        className
      )}
      {...props}
    >
      {children}
    </button>
  )
}

const PageBodyCarousel = ({
  images = [],
  nextSharesBg,
}: ReturnType<typeof mapDataToProps>): JSX.Element => {
  const [currIdx, setCurrIdx] = React.useState(0)
  const [sliderRef, slider] = useKeenSlider<HTMLUListElement>({
    loop: true,
    breakpoints: {
      '(min-width: 48rem)': {
        slidesPerView: 2,
      },
    },
    slidesPerView: 1.2,
    centered: true,
    slideChanged: (slider) => setCurrIdx(slider.details().relativeSlide),
  })

  const hasCaption = Boolean(images[currIdx]?.caption)

  return (
    <section
      className={clsx(
        'grid gap-y-4 sm:gap-y-6 md:gap-y-8',
        !nextSharesBg && 'pb-8 sm:pb-10 md:mb-16 lg:py-24'
      )}
    >
      <div className="grid gap-y-4">
        <ul ref={sliderRef} className="keen-slider cursor-[grab]">
          {images.map((image, idx) => (
            <li key={idx} className={clsx('keen-slider__slide')}>
              <div
                className={clsx(
                  'aspect-w-4 aspect-h-3',
                  'sm:aspect-w-16 sm:aspect-h-10'
                )}
              >
                <div>
                  <GatsbyImage
                    fluid={image?.image?.fluid as FluidObject}
                    alt={image?.image?.alt}
                    imgStyle={{ objectFit: 'cover' }}
                    className={clsx(
                      'w-full h-full transition filter',
                      currIdx === idx && 'grayscale-0 opacity-100',
                      currIdx !== idx && 'grayscale opacity-50'
                    )}
                  />
                </div>
              </div>
            </li>
          ))}
        </ul>

        {hasCaption && (
          <p
            className={clsx(
              'text-center sans-14-1_5 text-gray-55 font-medium max-w-[23ch]',
              'mx-auto',
              'sm:max-w-[600px]'
            )}
          >
            {images[currIdx]?.caption}
          </p>
        )}
      </div>

      <div className="flex justify-center space-x-6">
        <CircleButton onClick={() => slider.prev()}>
          <VisuallyHidden>Previous Image</VisuallyHidden>
          <ArrowLeftIcon className="w-5 h-5" />
        </CircleButton>

        <CircleButton onClick={() => slider.next()}>
          <VisuallyHidden>Next Image</VisuallyHidden>
          <ArrowRightIcon className="w-5 h-5" />
        </CircleButton>
      </div>
    </section>
  )
}

export function mapDataToProps(
  data: PageBodyCarouselFragment,
  metadata: MapToPropsMetadata
) {
  return {
    images: data.items,
    nextSharesBg: metadata?.nextContext?.bg === 'bg-white',
  }
}

export function mapDataToContext() {
  return {
    bg: 'bg-white',
  }
}

export const fragment = graphql`
  fragment PageBodyCarousel on PrismicPageBodyCarousel {
    items {
      caption
      image {
        alt
        fluid(maxWidth: 600) {
          ...GatsbyPrismicImageFluid
        }
      }
    }
  }
`

export default PageBodyCarousel
