import clsx from 'clsx'
import { graphql } from 'gatsby'
import * as ReachTabs from '@reach/tabs'

import { BoundedBoxTw } from '../components/BoundedBox'
import { HighlightedText } from '../components/HighlightedText'
import { MapToPropsMetadata } from '../components/MapToComponents'
import { PageBodyTabsFragment } from '../types.gql'

interface TabProps extends React.ComponentProps<'div'> {
  idx: number
}

const Tab = ({ children, idx, ...props }: TabProps) => {
  const { selectedIndex } = ReachTabs.useTabsContext()
  const isSelected = selectedIndex === idx
  const isFirst = idx === 0

  return (
    <div
      className={clsx(
        'px-4 py-5 transition border-green-48 border md:px-8 h-full flex flex-col',
        'justify-center',
        isFirst && 'border-r-0',
        isSelected ? 'bg-white border-b-0 pb-[21px]' : 'bg-green-84',
        'transform sm:translate-y-px'
      )}
      {...props}
    >
      <div className="text-center serif-18-1_25 md:serif-24-1_25">
        {children}
      </div>
    </div>
  )
}

interface TabPanelProps extends React.ComponentProps<'div'> {
  textFields: Array<{ bolded_text?: string; italic_text?: string }>
}

const TabPanel = ({ textFields, ...props }: TabPanelProps) => {
  return (
    <div
      className={clsx(
        'grid gap-8 p-6 md:py-16 md:px-24 sm:grid-cols-2 border-green-48',
        'border-l border-b border-r sm:border-t'
      )}
      {...props}
    >
      {textFields.map((tf, idx) => (
        <div key={idx} className="space-y-4">
          {tf.bolded_text && (
            <p className="font-bold sans-14-1_75">{tf.bolded_text}</p>
          )}
          {tf.italic_text && (
            <p className="italic sans-14-1_75">{tf.italic_text}</p>
          )}
        </div>
      ))}
    </div>
  )
}

const PageBodyTabs = ({
  smallHeading,
  text,
  nextSharesBg,
  tabs = [],
}: ReturnType<typeof mapDataToProps>): JSX.Element => {
  return (
    <BoundedBoxTw
      className={clsx(
        'py-8 sm:py-10 md:py-16 lg:py-24 bg-gray-93',
        nextSharesBg && 'pb-0 sm:pb-0 md:pb-0 lg:pb-0'
      )}
      maxWidthClassName="max-w-[860px]"
    >
      <div className="grid gap-y-8 justify-items-center sm:gap-y-16">
        <div className="grid gap-y-8 justify-items-center">
          {smallHeading && <HighlightedText>{smallHeading}</HighlightedText>}

          {text && (
            <p className="text-center serif-18-1_5 sm:serif-24-1_5">{text}</p>
          )}
        </div>

        <ReachTabs.Tabs className="w-full border-green-48">
          <ReachTabs.TabList
            className={clsx(
              'grid grid-flow-col auto-cols-fr md:auto-cols-auto justify-center',
              'bg-transparent'
            )}
          >
            {tabs.map((tab, idx) => (
              <ReachTabs.Tab
                key={idx}
                className="p-0 border-0 focus:outline-none focus:ring"
              >
                <Tab idx={idx}>{tab?.data?.title?.text}</Tab>
              </ReachTabs.Tab>
            ))}
          </ReachTabs.TabList>

          <ReachTabs.TabPanels className="bg-white">
            {tabs.map((tab, idx) => (
              <ReachTabs.TabPanel key={idx}>
                <TabPanel
                  textFields={
                    tab?.data?.text_fields as TabPanelProps['textFields']
                  }
                />
              </ReachTabs.TabPanel>
            ))}
          </ReachTabs.TabPanels>
        </ReachTabs.Tabs>
      </div>
    </BoundedBoxTw>
  )
}

export function mapDataToProps(
  data: PageBodyTabsFragment,
  metadata: MapToPropsMetadata
) {
  return {
    smallHeading: data.primary?.small_heading?.text,
    text: data.primary?.text?.text,
    nextSharesBg: metadata?.nextContext?.bg === 'bg-gray-93',
    tabs: data.items?.map((item) => item?.tab?.document),
  }
}

export function mapDataToContext() {
  return {
    bg: 'bg-gray-93',
  }
}

export const fragment = graphql`
  fragment PageBodyTabs on PrismicPageBodyTabs {
    primary {
      small_heading {
        text
      }
      text {
        text
      }
    }
    items {
      tab {
        document {
          ... on PrismicTab {
            id
            data {
              title {
                text
              }
              text_fields {
                bolded_text
                italic_text
              }
            }
          }
        }
      }
    }
  }
`

export default PageBodyTabs
