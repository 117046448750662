import clsx from 'clsx'
import { graphql } from 'gatsby'
import GatsbyImage from 'gatsby-image'
import ConditionalWrap from 'conditional-wrap'

import { BoundedBoxTw } from '../components/BoundedBox'
import { HighlightedText } from '../components/HighlightedText'
import { Link } from '../components/Link'
import { PageBodyLogosFragment } from '../types.gql'

const PageBodyLogos = ({
  heading,
  logos = [],
}: ReturnType<typeof mapDataToProps>): JSX.Element => {
  return (
    <BoundedBoxTw
      className={clsx(
        'py-16 lg:py-24 bg-white relative border-t border-green-48'
      )}
      maxWidthClassName="max-w-[860px]"
    >
      <div className="flex flex-col items-center space-y-8 text-center">
        {heading && <HighlightedText>{heading}</HighlightedText>}

        <ul
          className={clsx(
            'flex justify-center items-center flex-wrap -m-8',
            'w-[calc(100%+32px)]'
          )}
        >
          {logos.map((logo, idx) => {
            if (!logo.imageFluid) return null

            return (
              <li
                key={idx}
                className="flex-shrink-0 m-8 max-w-[600px] max-h-[600px]"
                style={{ width: logo.width, height: logo.height }}
              >
                <ConditionalWrap
                  condition={Boolean(logo.href)}
                  wrap={(children) => (
                    <Link href={logo.href}>
                      {logo.imageAlt && (
                        <span className="sr-only">
                          Navigate to {logo.imageAlt}
                        </span>
                      )}
                      {children}
                    </Link>
                  )}
                >
                  <GatsbyImage
                    fluid={logo.imageFluid}
                    alt={logo.imageAlt}
                    imgStyle={{ objectFit: 'contain' }}
                  />
                </ConditionalWrap>
              </li>
            )
          })}
        </ul>
      </div>
    </BoundedBoxTw>
  )
}

export function mapDataToProps(data: PageBodyLogosFragment) {
  return {
    heading: data.primary?.small_heading?.text,
    logos: data.items?.map((item) => ({
      imageFluid: item?.logo?.fluid,
      imageAlt: item?.logo?.alt,
      width: item?.logo?.dimensions?.width,
      height: item?.logo?.dimensions?.height,
      href: item?.link?.url,
    })),
  }
}

export function mapDataToContext() {
  return {
    bg: Symbol('never-share'),
  }
}

export const fragment = graphql`
  fragment PageBodyLogos on PrismicPageBodyLogos {
    primary {
      small_heading {
        text
      }
    }
    items {
      logo {
        dimensions {
          width
          height
        }
        fluid(maxWidth: 800) {
          ...GatsbyPrismicImageFluid
        }
        alt
      }
      link {
        url
      }
    }
  }
`

export default PageBodyLogos
