import { graphql } from 'gatsby'
import clsx from 'clsx'
import GatsbyImage, { FluidObject } from 'gatsby-image'

import { PageBodyHeroFragment } from '../types.gql'
import { BoundedBoxTw } from '../components/BoundedBox'
import { Button } from '../components/Button'
import { Link } from '../components/Link'

import * as styles from './PageBodyHero.module.css'

interface HeroImageProps {
  isPrimary?: boolean
  imageFluid: FluidObject
  imageAlt?: string
}

const HeroImage = ({
  isPrimary = false,
  imageFluid,
  imageAlt,
}: HeroImageProps) => {
  return (
    <div
      className={clsx(
        isPrimary ? 'aspect-w-1-25 aspect-h-1' : 'aspect-w-1 aspect-h-1',
        'sm:aspect-w-3 sm:aspect-h-3-4',
        isPrimary ? 'col-span-full' : 'col-span-1',
        'sm:col-span-1'
      )}
    >
      <div>
        <GatsbyImage
          fluid={imageFluid}
          alt={imageAlt}
          imgStyle={{
            objectFit: 'cover',
            objectPosition: 'center',
          }}
          className="w-full h-full"
        />
      </div>
    </div>
  )
}

const PageBodyHero = ({
  heading,
  buttonText,
  buttonURL,
  images,
}: ReturnType<typeof mapDataToProps>) => {
  return (
    <section>
      <BoundedBoxTw
        className={clsx(
          'text-center pt-16 pb-14 bg-gray-93',
          'md:pt-24 md:pb-20'
        )}
        innerClassName="flex flex-col items-center"
      >
        {heading && (
          <h2
            className={clsx(
              'serif-24-1_5 sm:serif-30-1_5 md:serif-36-1_5',
              'mb-8 max-w-[35ch] mx-auto font-normal md:mb-10'
            )}
          >
            {heading}
          </h2>
        )}

        {buttonURL && (
          <Button
            as={Link}
            href={buttonURL}
            variant="primary"
            className="block"
          >
            {buttonText}
          </Button>
        )}
      </BoundedBoxTw>

      <div className="relative bg-gray-93">
        <div
          className={clsx('absolute inset-0 bg-green-48', styles.curvedBorder)}
        />

        <div
          className={clsx(
            'grid relative grid-cols-2 grid-rows-[auto,auto]',
            'sm:grid-cols-3 sm:grid-rows-[auto]',
            styles.curvedImages
          )}
        >
          {images.map((image, idx) => (
            <HeroImage
              imageFluid={image.fluid}
              imageAlt={image.alt}
              isPrimary={idx === 0}
              key={idx}
            />
          ))}
        </div>
      </div>
    </section>
  )
}

export function mapDataToProps(data: PageBodyHeroFragment) {
  return {
    heading: data.primary?.heading?.text,
    buttonText: data.primary?.button_text ?? 'Learn More',
    buttonURL: data.primary?.button_url?.url,
    images:
      data.items?.map((item) => ({
        alt: item?.image?.alt,
        fluid: item?.image?.fluid as FluidObject,
      })) ?? [],
  }
}

export const fragment = graphql`
  fragment PageBodyHero on PrismicPageBodyHero {
    primary {
      button_text
      button_url {
        url
      }
      heading {
        text
      }
    }
    items {
      image {
        fluid(maxWidth: 1000) {
          ...GatsbyPrismicImageFluid
        }
        alt
      }
    }
  }
`

export default PageBodyHero
