import * as React from 'react'
import { graphql } from 'gatsby'
import clsx from 'clsx'
import { ArrowSmRightIcon } from '@heroicons/react/outline'
import type { ViewportProps } from 'react-map-gl'

import { BoundedBoxTw } from '../components/BoundedBox'
import { MapToPropsMetadata } from '../components/MapToComponents'
import { InfoBox } from '../components/InfoBox'
import { TLocation, useAllLocations } from '../hooks/useAllLocations'
import { ReactMapGLModule, useMapGL } from '../hooks/useMapGL'
import { HighlightedText } from '../components/HighlightedText'
import { HTMLContent } from '../components/HTMLContent/HTMLContent'
import { TextIconButton } from '../components/TextIconButton'
import { Link } from '../components/Link'
import { DEFAULT_MAP_CENTER, MAPBOX_API_TOKEN } from '../lib/constants'
import { PageBodyMapFragment } from '../types.gql'

import locationMarkerUrl from '../assets/icon-locationmarker.svg'
import { scrollToElement } from '../lib/scrollToElement'
import { getDirectionsUrl } from '../lib/getDirectionsUrl'

interface MapProps {
  reactMapGL: ReactMapGLModule
  locations: TLocation[]
  viewport: ViewportProps
  setViewport: React.Dispatch<React.SetStateAction<ViewportProps>>
  mapContainerRef: React.RefObject<HTMLDivElement>
}

const Map = ({
  reactMapGL,
  locations,
  viewport,
  setViewport,
  mapContainerRef,
}: MapProps) => {
  return (
    <div className="bg-gray-55 min-h-[400px]" ref={mapContainerRef}>
      <reactMapGL.InteractiveMap
        {...viewport}
        width="100%"
        height="100%"
        mapStyle="mapbox://styles/mapbox/light-v9"
        onViewportChange={setViewport}
        mapboxApiAccessToken={MAPBOX_API_TOKEN}
        scrollZoom={false}
        minZoom={9}
        doubleClickZoom
      >
        {locations.map((l, idx) => {
          if (!l.location) return

          return (
            <reactMapGL.Marker
              key={idx}
              latitude={l.location.latitude}
              longitude={l.location.longitude}
              className="w-5 h-[30px]"
              offsetTop={-30}
              offsetLeft={-10}
            >
              <img
                className="w-5 h-[30px] object-cover"
                src={locationMarkerUrl}
              />
            </reactMapGL.Marker>
          )
        })}
      </reactMapGL.InteractiveMap>
    </div>
  )
}

interface LocationCardProps extends TLocation {
  mapGL?: ReactMapGLModule
  setViewport: React.Dispatch<React.SetStateAction<ViewportProps>>
  mapContainerRef: React.RefObject<HTMLDivElement>
}

const LocationCard = React.memo(
  ({
    title,
    addressHTML,
    phoneNumber,
    fax,
    hoursHTML,
    location,
    mapGL,
    setViewport,
    mapContainerRef,
  }: LocationCardProps) => {
    const flyToLocation = () => {
      if (!mapGL) return
      if (mapContainerRef.current) {
        scrollToElement(mapContainerRef.current, -32)
      }

      const { FlyToInterpolator } = mapGL

      /**
       * The types for `react-map-gl` are incorrect by specifying
       * `transitionDuration` can only be set to `number | undefined`.  Their
       * docs state that a value of `'auto'` is valid.
       *
       * @see https://visgl.github.io/react-map-gl/docs/advanced/viewport-transition
       */
      // @ts-expect-error
      setViewport((prevViewport) => ({
        ...prevViewport,
        longitude: location?.longitude,
        latitude: location?.latitude,
        transitionDuration: 'auto',
        transitionInterpolator: new FlyToInterpolator(),
      }))
    }

    return (
      <li>
        <InfoBox
          as="button"
          className="w-full space-y-8 text-left"
          onClick={flyToLocation}
          aria-label={`Pan map over to ${title} location.`}
        >
          {(title || addressHTML) && (
            <div className="space-y-6">
              {title && <HighlightedText>{title}</HighlightedText>}
              {addressHTML && (
                <HTMLContent
                  html={addressHTML}
                  variant="map"
                  className="space-y-4"
                />
              )}
            </div>
          )}
          {(phoneNumber || fax) && (
            <div className="space-y-4">
              <p className="font-bold sans-14-1_75">Contact</p>
              {phoneNumber && (
                <p className="sans-14-1_75">
                  Phone:{' '}
                  <a
                    href={`tel:${phoneNumber}`}
                    className="focus:ring focus:outline-none hover:underline text-green-14"
                  >
                    {phoneNumber}
                  </a>
                </p>
              )}
              {fax && (
                <p className="sans-14-1_75">
                  Fax:{' '}
                  <a
                    href={`tel:${phoneNumber}`}
                    className="focus:ring focus:outline-none hover:underline text-green-14"
                  >
                    {fax}
                  </a>
                </p>
              )}
            </div>
          )}
          {hoursHTML && (
            <div className="space-y-4">
              <p className="font-bold sans-14-1_75">Hours</p>
              <HTMLContent
                html={hoursHTML}
                variant="map"
                className="space-y-4"
              />
            </div>
          )}

          {title && (
            <TextIconButton
              as={Link}
              icon={ArrowSmRightIcon}
              href={getDirectionsUrl(title)}
              className="focus:ring focus:outline-none"
              // We stop propagation here to prevent this click event from moving
              // the map viewport.
              //@ts-expect-error - Types are only invalid due to polymorphism.
              onClick={(e: React.MouseEvent<MouseEvent>) => e.stopPropagation()}
            >
              Get Directions
            </TextIconButton>
          )}
        </InfoBox>
      </li>
    )
  },
  (prevProps, nextProps) => {
    // We only want to re-render when `react-map-gl` module is lazily-loaded.
    // This only happens when the previous render has no `mapGL` and the next
    // render does.
    if (!prevProps.mapGL && nextProps.mapGL) return false

    // Otherwise, we can safely assume that our props will never change since
    // our props are sourced from static data. The, `setViewport` prop is a
    // stable function that never changes since it comes from `React.useState`.
    return true
  }
)

interface LocationCardsAndMapProps {
  locations: TLocation[]
  longitude: number
  latitude: number
}

/**
 * This component is largely used to avoid hoisting the viewport state into
 * the slice component. Since the viewport state could be rapidly changing,
 * we want to keep it as close as possible to the components that need it.
 */
const LocationCardsAndMap = ({
  locations,
  latitude,
  longitude,
}: LocationCardsAndMapProps) => {
  const mapContainerRef = React.useRef<HTMLDivElement>(null)
  const { reactMapGL } = useMapGL()
  const [viewport, setViewport] = React.useState<ViewportProps>(() => ({
    latitude,
    longitude,
    zoom: 11,
  }))

  return (
    <div className="grid gap-y-8 md:grid-cols-[400px,1fr] lg:grid-cols-[425px,1fr]">
      <ul className="border divide-y border-green-48 divide-green-48">
        {locations.map((l, idx) => (
          <LocationCard
            key={idx}
            addressHTML={l.addressHTML}
            addressText={l.addressText}
            phoneNumber={l.phoneNumber}
            title={l.title}
            fax={l.fax}
            hoursHTML={l.hoursHTML}
            location={l.location}
            directionsHref={l.directionsHref}
            setViewport={setViewport}
            mapGL={reactMapGL}
            mapContainerRef={mapContainerRef}
          />
        ))}
      </ul>

      {!reactMapGL && (
        <div className="bg-gray-70 animate-pulse min-h-[400px]" />
      )}
      {reactMapGL && (
        <Map
          reactMapGL={reactMapGL}
          locations={locations}
          viewport={viewport}
          setViewport={setViewport}
          mapContainerRef={mapContainerRef}
        />
      )}
    </div>
  )
}

const PageBodyMap = ({
  initialLatitude = DEFAULT_MAP_CENTER.latitude,
  initialLongitude = DEFAULT_MAP_CENTER.longitude,
  nextSharesBg,
}: Partial<ReturnType<typeof mapDataToProps>>) => {
  const locations = useAllLocations()

  return (
    <BoundedBoxTw
      as="section"
      className={clsx(
        'bg-gray-93',
        'py-16 md:py-[4.5rem] lg:py-24',
        nextSharesBg && 'pb-0 md:pb-0 lg:pb-0'
      )}
      maxWidthClassName="max-w-md"
    >
      <LocationCardsAndMap
        locations={locations}
        longitude={initialLongitude}
        latitude={initialLatitude}
      />
    </BoundedBoxTw>
  )
}

export function mapDataToProps(
  data: PageBodyMapFragment,
  metadata: MapToPropsMetadata
) {
  return {
    nextSharesBg: metadata?.nextContext?.bg === 'bg-gray-93',
    initialLongitude:
      data.primary?.map_center?.longitude ?? DEFAULT_MAP_CENTER.longitude,
    initialLatitude:
      data.primary?.map_center?.latitude ?? DEFAULT_MAP_CENTER.latitude,
  }
}

export function mapDataToContext() {
  return {
    bg: 'bg-gray-93',
  }
}

export const fragment = graphql`
  fragment PageBodyMap on PrismicPageBodyMap {
    primary {
      map_center {
        longitude
        latitude
      }
    }
  }
`

export default PageBodyMap
