/**
 * Helper utilty to help facilitate scrolling to an element.
 *
 * @param element The element to scroll to.
 * @param yOffset If provided, the amount of pixels to offset scrolling to the
 * element by.
 *
 * @returns void
 */
export function scrollToElement(element: HTMLElement, yOffset?: number) {
  if (!yOffset) {
    return element.scrollIntoView({ behavior: 'smooth' })
  }

  window.scrollTo({
    top: element.getBoundingClientRect().top + window.pageYOffset + yOffset,
    behavior: 'smooth',
  })
}
