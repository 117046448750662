import clsx from 'clsx'
import { graphql } from 'gatsby'
import GatsbyImage from 'gatsby-image'
import { BoundedBoxTw } from '../components/BoundedBox'
import { HTMLContent } from '../components/HTMLContent/HTMLContent'
import { MapToPropsMetadata } from '../components/MapToComponents'
import { PageBodyTextWithImageFragment } from '../types.gql'

const PageBodyTextWithImage = ({
  imageAlt,
  imageFluid,
  textHTML,
  nextSharesBg,
}: ReturnType<typeof mapDataToProps>) => {
  return (
    <BoundedBoxTw
      as="section"
      maxWidthClassName="max-w-md"
      className={clsx(
        'py-8 sm:py-10 md:py-16 lg:py-24',
        nextSharesBg && 'pb-0 sm:pb-0 md:pb-0 lg:pb-0'
      )}
    >
      <div className="grid gap-y-6 sm:grid-cols-2 sm:items-center">
        {imageFluid && (
          <div
            className={clsx(
              'aspect-w-4 aspect-h-3',
              'xs:aspect-w-16 xs:aspect-h-10'
            )}
          >
            <div>
              <GatsbyImage
                fluid={imageFluid}
                alt={imageAlt}
                className="w-full h-full"
              />
            </div>
          </div>
        )}

        {textHTML && (
          <HTMLContent
            html={textHTML}
            variant="textWithImageHtmlContent"
            className={clsx('sm:ml-20 sm:max-w-[360px]', 'md:ml-28')}
          />
        )}
      </div>
    </BoundedBoxTw>
  )
}

export function mapDataToProps(
  data: PageBodyTextWithImageFragment,
  metadata: MapToPropsMetadata
) {
  return {
    imageFluid: data.primary?.image?.fluid,
    imageAlt: data.primary?.image?.alt,
    textHTML: data.primary?.text?.html,
    nextSharesBg: metadata?.nextContext?.bg === 'bg-white',
  }
}

export function mapDataToContext() {
  return {
    bg: 'bg-white',
  }
}

export const fragment = graphql`
  fragment PageBodyTextWithImage on PrismicPageBodyTextWithImage {
    primary {
      image {
        fluid(maxWidth: 800) {
          ...GatsbyPrismicImageFluid
        }
        alt
      }
      text {
        html
      }
    }
  }
`

export default PageBodyTextWithImage
