import clsx from 'clsx'
import type { PolymorphicPropsWithoutRef } from 'react-polymorphic-types'
import { IconButton } from './IconButton'

const defaultElement = 'button'

interface OwnProps {
  icon: (props: React.ComponentProps<'svg'>) => JSX.Element
  label?: string
  children: string
}

export type TextIconButtonProps<
  T extends React.ElementType = typeof defaultElement
> = PolymorphicPropsWithoutRef<OwnProps, T>

export const TextIconButton = <
  T extends React.ElementType = typeof defaultElement
>({
  as,
  icon,
  children,
  className,
  label,
  ...props
}: TextIconButtonProps<T>) => {
  const Element: React.ElementType = as || defaultElement

  return (
    <Element
      className={clsx(className, 'flex items-center space-x-3')}
      {...props}
    >
      <IconButton as="div" label={label ?? children} icon={icon} />
      <p className="font-semibold uppercase sans-14-1_5 tracking-caps">
        {children}
      </p>
    </Element>
  )
}
